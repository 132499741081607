<template>
  <div id="main-page">
    <div class="container">
      <app-bar
        :title="`Layanan ${activeService?.nama || ''}`"
        previousLink="/"
      />
      <!-- <div class="info pt-2 d-md-none">
        <router-link
          :to="{
            name: 'SelectBranch',
            query: {
              redirectTo: $route.path
            }
          }"
          class="address-box d-md-none mx-3 my-2 rounded d-flex flex-column">
          <strong>{{ selectedBranch.nama }}</strong>

          <small>{{ selectedBranch.alamat }}</small>
          <i class="ri ri-arrow-right-s-line"></i>
        </router-link>
      </div> -->

      <div
        v-if="loading.detailServices && detailServices.length < 1"
        class="d-flex justify-content-center align-items-center px-3 pt-5 mt-3 text-muted">
        Loading..
      </div>

      <!-- Empty -->
      <div
        v-else-if="detailServices.length < 1"
        class="d-flex flex-column justify-content-center align-items-center pt-5 mt-3 text-muted">
        Tidak ada layanan di cabang ini.

        <router-link
          :to="{
            name: 'SelectBranch',
            query: {
              redirectTo: $route.path
            }
          }"
          class="btn btn-outline-secondary mt-3">
          Cari Cabang lain
        </router-link>
      </div>

      <div v-else class="row">
        <!-- Layanan -->
        <div class="col-12 col-md-8 fixed-height">
          <div class="row">
            <div
              :key="index"
              v-for="(service, index) in detailServices"
              class="col-md-4 px-3">
              <div role="button" @click.prevent="onServiceClick(service)">
                <div class="card card-service border-0">
                  <div class="card-body d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                      <div class="service-icon me-2">
                        <img
                          :src="service.icon"
                          onload="this.style.backgroundColor = 'transparent'">
                      </div>
                      <div class="bd-highlight">
                        <div class="d-flex flex-column bd-highlight">
                          <div class="service-title fw-700 fs-14 lh-16 color-neutral-900 mb-1">
                            {{ service.nama }}
                          </div>
                          <div class="service-price fw-400 fs-12 lh-14 color-secondary-500">
                            Rp. {{ service.harga?.toLocaleString('id-ID') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        class="btn btn-toggle bg-transparent p-0 icon-wrapper"
                        :class="{
                          'active': cart.arr_paket.find(_temp => _temp.kode === service.kode)
                        }"
                        @click.stop="prepareAddToCart(service)"
                      >
                        <img src="@/assets/icon/icon-plus.svg" class="add" alt="Ikon tambah">
                        <img src="@/assets/icon/icon-close.svg" class="remove" alt="Ikon hapus">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Bottom Bar -->
        <div class="col-md-4">
          <div class="booking-summary">
            <div class="card card-summary-order border-0">
              <div class="row justify-content-center pt-2">
                <div class="tab-line bg-neutral-300" />
              </div>
              <div
                class="card-body pb-1 d-flex flex-md-column"
                :class="{
                  'flex-row justify-content-between': !isSummaryForMobileShown,
                  'flex-column': isSummaryForMobileShown
                }">
                <div class="d-flex flex-row align-items-start justify-content-between py-md-2">
                  <div
                    class="d-flex align-items-center gap-2"
                    :class="{
                      'mb-3': isSummaryForMobileShown
                    }">
                    <div class="icon-wrapper">
                      <img
                        src="@/assets/icon/cart.png"
                        alt=""
                        class="summary-icon"
                      >
                    </div>
                    <div>
                      <p class="ringkasan-pemesanan fw-400 fs-10 lh-12 color-neutral-600 mb-1">
                        Ringkasan Pemesanan
                      </p>
                      <strong class="total-service fw-700 fs-12 lh-14 color-neutral-900">
                        {{ tempCart?.length }} layanan terpilih
                      </strong>
                    </div>
                  </div>
                  <div
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <button class="btn btn-delete bg-gray-6" @click="clearCart">
                      <p class="fst-normal mb-0 text-gray-3 fw-600 fs-14 fm-nunito">Hapus Semua</p>
                    </button>
                  </div>
                </div>
                <section
                  :class="{
                    'd-none d-md-block': !isSummaryForMobileShown
                  }">
                  <div
                    v-for="(summary) in Object.keys(summaries)" :key="summary" class="group d-flex flex-column">
                    <div class="py-2">
                      <p class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">
                        {{ summary }}
                      </p>
                    </div>
                    <div v-for="detail in summaries[summary]" :key="detail" class="py-2">
                      <p class="fst-normal text-gray-3 fs-14 mb-0 fm-nunito">
                        <button class="btn" @click="removeServiceFromCart(detail)">
                          <i class="text-danger ri-indeterminate-circle-line"></i>
                        </button>
                        {{ detail.nama }}
                      </p>
                    </div>
                  </div>
                </section>
                <div>
                  <div
                    class="divider pt-3"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <hr class="border bg-gray-6 my-0">
                  </div>
                  <div
                    class="py-2"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <div class="d-flex flex-row align-items-center justify-content-between py-2">
                      <div>
                        <p class="text-gray-3 fs-14 lh-19 mb-0">
                          Total Layanan
                        </p>
                      </div>
                      <div>
                        <p class="text-gray-1 fs-14 fw-600 mb-0">
                          {{ tempCart?.length }} Layanan
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="pb-3 d-flex flex-row-reverse">
                    <router-link
                      class="btn py-2 d-md-none ms-2"
                      :class="{
                        [`btn-${isSummaryForMobileShown ? 'light' : 'primary'}`]: true
                      }"
                      :to="{
                        query: { showSummary: !isSummaryForMobileShown }
                      }">
                      <i
                        :class="{
                          [`ri-arrow-${isSummaryForMobileShown ? 'down' : 'up'}-s-line`]: true
                        }"
                      />
                    </router-link>
                    <button
                      class="btn btn-primary w-100 py-2 flex-grow-1"
                      :class="{
                        'd-none d-md-block': !isSummaryForMobileShown
                      }"
                      @click="prepareToCart">
                      Lanjutkan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getServiceDetails,
  detailServices,
  loading,
  activeService,
  services,
  detailService,
  loadServices
} from '@/core/service'
import { nextTick, onMounted, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AppBar from '@/components/AppBar.vue'
import { title } from '@/core/page'
import { selectedBranch } from '@/core/subDivisions'
import { analytics } from '@/core/firebase-messaging'
import { addToCart, cart, removeFromCart, getCart } from '@/core/cart'
import { showNotif } from '@/core/utility'

export default {
  components: {
    AppBar
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const isSummaryForMobileShown = computed(() => route.query.showSummary === 'true')
    const tempCart = ref([])

    const summaries = computed(() => {
      const temp = []
      tempCart.value.map((cart) => {
        if (!temp[cart.nama_group]) {
          temp[cart.nama_group] = [cart]
        } else {
          temp[cart.nama_group].push(cart)
        }
      })
      return temp
    })

    const updateCart = async () => {
      // Kosongkan dulu tempcart
      tempCart.value = []

      // Ambil data cart dari online
      await getCart()
      for (let i = 0; i < cart.value.arr_paket.length; i++) {
        if (cart.value.arr_paket[i].id_group !== null) {
          tempCart.value.push(cart.value.arr_paket[i])
        }
      }
    }

    const removeServiceFromCart = async (item) => {
      const itemIndex = tempCart.value.findIndex(_item => _item.id_dt === item.id_dt)
      await removeFromCart(tempCart.value[itemIndex])

      // Get data terbaru setelah proses
      await updateCart()
    }

    const clearCart = async () => {
      for (let i = 0; i < tempCart.value.length; i++) {
        if (tempCart.value[i].id_dt !== null) {
          await removeFromCart(tempCart.value[i])
        }
      }
      await updateCart()
    }

    const prepareToCart = () => {
      if (tempCart.value.length !== 0) {
        // Arahkan ke halaman selanjutnya
        router.push({
          name: window.innerWidth < 580 ? 'Keranjang' : 'Checkout'
        })
      }
    }

    const prepareAddToCart = async (item) => {
      // cek cabang
      if (!selectedBranch.value) {
        // jika cabang kosong maka di redirect pilih cabang
        return router.push({
          name: 'SelectBranch',
          query: {
            redirectTo: route.path
          }
        })
      }

      const itemIndex = tempCart.value.findIndex(_item => _item.id_dt === item.id_dt)

      if (itemIndex > -1) {
        await removeFromCart(tempCart.value[itemIndex])
      } else {
        const result = await addToCart({
          id_dt: item.id_dt,
          kode: item.kode,
          nama: item.nama,
          harga: item.harga,
          group: activeService.value?.nama || 'Covid-19'
        })

        // handling sukses / gagal
        if (!result.status) {
          let message = result.message
          if (message === 'login') {
            message = 'Silahkan login terlebih dahulu'
            router.push({
              name: 'Login'
            })
          }
          // Trigger toast
          showNotif({
            status: 'error',
            message: message
          })
        }
      }
      await updateCart()
    }

    onMounted(async () => {
      await getCart()
      if (services.value.length < 1) {
        await loadServices()
      }

      activeService.value = services.value
        .find(_service => {
          return parseInt(_service.id_group) === parseInt(route.params.id_layanan)
        })

      nextTick(() => {
        title.value = activeService.value.nama
      })

      await getServiceDetails({
        cabang: selectedBranch.value.id,
        idGroup: route.params.id_layanan
      })

      /**
       * Tracking item yang dilihat user
       */
      try {
        const payload = {
          items: detailServices.value.map(_service => ({
            item_id: _service.kode,
            item_name: _service.nama,
            price: parseFloat(_service.harga),
            currency: 'IDR',
            item_category: activeService.value.nama,
            item_list_name: `${activeService.value.nama} cabang ${selectedBranch.value.nama}`,
            item_list_id: `${activeService.value.id_group}_cabang_${selectedBranch.value.id}`
          })),
          item_list_name: `${activeService.value.nama} cabang ${selectedBranch.value.nama}`,
          item_list_id: `main_service_${activeService.value.id_group}_cabang_${selectedBranch.value.id}`
        }

        analytics.logEvent('view_item_list', payload)
      } catch (error) {
        console.error(error)
      }

      // Refresh cart
      await updateCart()
    })

    const onServiceClick = service => {
      detailService.value = service
      router.push({
        name: 'SubServiceDetail',
        params: {
          id_layanan: route.params.id_layanan,
          id_dt: service.id_dt
        }
      })
    }

    return {
      loading,
      detailServices,
      activeService,
      selectedBranch,
      onServiceClick,
      isSummaryForMobileShown,
      cart,
      clearCart,
      summaries,
      tempCart,
      removeServiceFromCart,
      prepareToCart,
      prepareAddToCart
    }
  }
}
</script>

<style lang="scss" scoped>
#main-page {
    width: 100%;
    height: auto;
}

// .info {
//   position: fixed;
//   z-index: 98;
//   background-color: white;
//   top: 3.25rem;
//   left: 0;
//   width: 100%;
//   border-bottom: 1px solid rgb(247, 247, 247);
// }

// .address-box {
//     position: relative;

//     .ri {
//         position: absolute;
//         right: 0rem;
//         top: 50%;
//         transform: translateY(-50%);
//         color: rgb(153, 153, 153);
//     }
// }

.card-service {
  .card-body {
    padding: 12px 0px;
    box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
    width: 100%;

    .service-icon {
      width: 42px;
      height: 42px;
    }

    .service-icon img {
      width: 100%;
      height: 100%;

      &:not(.loaded) {
        background-color: #F2F2F2;
      }
    }

    .icon-wrapper img {
      width: 20px;
      height: auto;
    }
  }
}

.booking-summary {
  @media (max-width: 520px) {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    max-height: 93vh;

    section {
      max-height: 61vh;
      overflow-y: auto;
    }
  }

  .card-summary-order {
    border-radius: 10px !important;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);

    .tab-line {
      width: 48px;
      height: 4px;
      border-radius: 14px;
    }

    .ringkasan-pemesanan {
      font-size: 10px;
      line-height: 12px;

      @media (max-width: 520px) {
        color: rgb(179, 179, 179);
      }
    }

    .total-service {
      font-size: 12px;
      line-height: 14px;
    }

    .btn-payment {
      box-shadow: none;
    }
  }
}

.remove {
  display: none;
}

.active {
  .add {
    display: none;
  }

  .remove {
    display: block;
  }
}
</style>
